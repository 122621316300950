import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  PaginationState,
  ResourcePaginationStateReady,
} from '@wix/comments-ooi-client/controller';

import { EFeedStatus, FeedStateExtras } from './types';
import {
  fetch,
  fetchNext,
  react,
  unreact,
  pin,
  unpin,
  subscribe,
  unsubscribe,
} from './actions';
import { feedItemsAdapter } from './adapter';

export const initialState = feedItemsAdapter.getInitialState<FeedStateExtras>({
  status: EFeedStatus.init,
});

export const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    updateTotalComments(state, action: PayloadAction<PaginationState>) {
      feedItemsAdapter.updateMany(state, totalCommentsChanges(action.payload));
    },
  },
  extraReducers(builder) {
    builder.addCase(fetch.pending, (state) => ({
      ...state,
      status: EFeedStatus.loading,
    }));
    builder.addCase(fetch.rejected, (state) => ({
      ...state,
      status: EFeedStatus.error,
    }));

    builder.addCase(subscribe.fulfilled, function (state, action) {
      feedItemsAdapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          requesterContext: action.payload.data,
        },
      });
    });

    builder.addCase(unsubscribe.fulfilled, function (state, action) {
      feedItemsAdapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          requesterContext: action.payload.data,
        },
      });
    });

    builder.addCase(pin.fulfilled, function (state, action) {
      feedItemsAdapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          pin: action.payload.data,
        },
      });
    });

    builder.addCase(unpin.fulfilled, function (state, action) {
      feedItemsAdapter.updateOne(state, {
        id: action.meta.arg,
        changes: {
          pin: undefined,
        },
      });
    });

    builder.addCase(fetch.fulfilled, function (state, action) {
      const { data } = action.payload;

      state.groupId = action.meta.arg;
      state.status = EFeedStatus.success;
      state.total = data.total;
      state.nextCursor = data.nextCursor;
      state.prevCursor = data.prevCursor;

      feedItemsAdapter.setAll(state, data.items);
    });

    builder.addCase(fetchNext.fulfilled, function (state, action) {
      const { data } = action.payload;

      state.total = data.total;
      state.nextCursor = data.nextCursor;
      state.prevCursor = data.prevCursor;

      feedItemsAdapter.addMany(state, data.items);
    });

    builder.addCase(react.fulfilled, function (state, action) {
      feedItemsAdapter.updateOne(state, {
        id: action.meta.arg.feedItemId,
        changes: {
          reactions: action.payload.data,
        },
      });
    });

    builder.addCase(unreact.fulfilled, function (state, action) {
      feedItemsAdapter.updateOne(state, {
        id: action.meta.arg.feedItemId,
        changes: {
          reactions: action.payload.data,
        },
      });
    });
  },
});

function totalCommentsChanges(resources: PaginationState) {
  return Object.entries(resources).map((data) => {
    const [id, state] = data as [string, ResourcePaginationStateReady];

    return {
      id,
      changes: {
        comments: {
          total: state.totals.topLevelComments,
        },
      },
    };
  });
}
