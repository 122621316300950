import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import {
  initializeCommentsController,
  PaginationState,
} from '@wix/comments-ooi-client/controller';

import settingsParams from './settingsParams';

import { createStore } from './store';

import { groupActions } from './store/group';
import { feedSelectors } from './store/feed/adapter';
import { feedActions } from './store/feed';
import { applicationActions } from './store/application';

const createController: CreateControllerFn = async (controllerContext) => {
  const { flowAPI } = controllerContext;
  const { wixCodeApi, setProps, appParams } = flowAPI.controllerConfig;

  const groupId = flowAPI.settings.get(settingsParams.groupId);

  const store = createStore(controllerContext);

  return {
    async pageReady() {
      if (groupId) {
        await fetchGroup(groupId);
      }

      setProps({
        store: store.getState(),
        group: {
          fetchGroup,
        },
        feed: {
          pin,
          unpin,
          fetch,
          react,
          unreact,
          subscribe,
          unsubscribe,
          fetchMore,
        },
      });

      store.subscribe(() => {
        setProps({
          store: store.getState(),
        });
      });

      wixCodeApi.user.onLogin(handleUserLogin);

      const commentsApi = await initializeCommentsController(
        flowAPI.controllerConfig,
        {
          appDefinitionId: appParams.appDefinitionId,
          httpClient: flowAPI.httpClient,
        },
      );

      commentsApi.watch.pagination.onChange(handleCommentsChange);
    },
  };

  function fetchGroup(groupId: string) {
    return store.dispatch(groupActions.fetchGroup(groupId));
  }

  function handleUserLogin() {
    store.dispatch(
      applicationActions.login({
        instance: wixCodeApi.site.getAppToken!(appParams.appDefinitionId),
        user: wixCodeApi.user.currentUser,
      }),
    );
    fetchGroup(groupId);
  }

  function subscribe(feedItemId: string) {
    return store.dispatch(feedActions.subscribe(feedItemId));
  }

  function unsubscribe(feedItemId: string) {
    return store.dispatch(feedActions.unsubscribe(feedItemId));
  }

  async function pin(feedItemId: string) {
    const { feed } = store.getState();

    const pinned = feedSelectors.selectAll(feed).find((item) => !!item.pin);

    if (pinned) {
      await unpin(pinned.feedItemId!);
    }

    return store.dispatch(feedActions.pin(feedItemId));
  }

  function unpin(feedItemId: string) {
    return store.dispatch(feedActions.unpin(feedItemId));
  }

  function react(feedItemId: string, code: string) {
    return store.dispatch(feedActions.react({ feedItemId, code }));
  }

  function unreact(feedItemId: string, code: string) {
    return store.dispatch(feedActions.unreact({ feedItemId, code }));
  }

  function fetch(groupId: string) {
    return store.dispatch(feedActions.fetch(groupId));
  }

  function fetchMore(limit: number = 10) {
    return store.dispatch(feedActions.fetchNext(limit));
  }

  function handleCommentsChange(state: PaginationState) {
    return store.dispatch(
      feedActions.updateTotalComments(
        Object.fromEntries(
          Object.entries(state).filter((data) => {
            const [, state] = data;

            return state.type === 'READY';
          }),
        ),
      ),
    );
  }
};

export default createController;
